// ==============================
// Custom style
// 自定义样式
// ==============================

$header-title-font-size: 1.2rem !default;

@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@800&display=swap');
$code-font-family: Fira Mono, Source Code Pro, Menlo, Consolas, Monaco, monospace;

// background-color and foreground color for dark mode
    // Define a variable for the common selector
    $dark-theme-selector: "body[theme=dark]";

    // Apply the common selector to different elements
    #{$dark-theme-selector} {
    color: #e0ddd9;
    background-color: #181a1b;
    
    .home .home-profile {
        color: #a9a9b3;
    }
    .home .home-profile .home-disclaimer{
        color:#3d3d3d;
    }
    .header-wrapper, #header-desktop, #header-mobile, .footer, .footer-container {
    background-color: #080909;
    }

    strong {
        // color: rgb(235, 13, 13) !important;
        text-shadow: 0 0 3px rgba(255, 0, 0, 0.877);
        // transition: text-shadow 0.3s ease-in-out;
        // &:hover {
        //   text-shadow: 0 0 20px red;
        // }
    }

}

    .home .home-profile .home-disclaimer{
        font-size:0.9rem;
    }

    strong {
        // color: black;
        text-shadow: 0 0 1px rgb(255, 115, 0);
        // transition: text-shadow 0.3s ease-in-out;
      
        // &:hover {
        //   text-shadow: 0 0 20px red;
        // }
      }


// background-color and foreground color for light mode
 
    #header-desktop, #header-mobile, .footer {
    background-color: rgb(243, 239, 239);
    }



// home page avatar change the width.
    .home {
        .home-profile {
        .home-avatar {
            img {
            width: 9rem;
            box-shadow:none;
            }
        }
        }
    }
    

// Define a mixin for the box-shadow property, this glows the header title. Light bulb kinda looks under the title bar.
    @mixin box-shadow {
        box-shadow: 0 3px 13px rgba(100,110,140,.1),0 2px 4px rgba(136, 100, 85, 0.149);
    }
    
    // Apply the box-shadow mixin to different classes and IDs
    .header-wrapper, #header-desktop, #header-mobile {
        @include box-shadow;
    }

// Blinky Cursor Animation
    .logo {
        &__cursor {
        display: inline-block;
        width: 6px;
        height: 0.8rem;
        background: #fe5186;
        border-radius: 1px;
        margin-left: -6px;
        animation: cursor 1.5s infinite;
        }

        // This code was conflicting the animation with none and over-ride and work laptop did not blink.
        // @media (prefers-reduced-motion: reduce) {
        // &__cursor {
        //     animation: none;
        // }
        // }
    
    }
    
    @keyframes cursor {
        0% { opacity: 0; }
        50% { opacity: 1; }
        100% { opacity: 0; }
    }

    // Override header font-size
    .header-title {
        font-family: "Source Code Pro", monospace;;
        font-size: var(--header-title-font-size);
    }

    // Override the variable with a new value
    :root {
        --header-title-font-size: 1.05rem;
    }

// This is used to make image inside blockquote to render the texts along with.

    div#sectionleft {
        float: left;
        margin-left:1rem;
    }

    div#sectionleft img {
        margin-top: 1rem;
        margin-right:1rem;
        margin-left:0rem;
        width: 13rem;
        height:auto;
        border-radius: 50%;
        box-shadow: 0 2px 4px #9fa093
    }

    // Used to remove the left bar on blockquote
    
    .renderblockquote .content blockquote{
        border-left: 0rem;
        // background-color: #d95b8f33;
    }


// Simple dashed hrule for styles
#myrule hr {
    margin: 1rem 0;
    position: relative;
    border-top: 1px dashed #dddde4;
    border-bottom: none;

    [theme=dark] & {
      border-top: 1px dashed #454546;
    }
  }


// adjustment to Home/Back button
    .single .post-footer .post-info-more{
        justify-content: center;
    }

// User Icon on single post
    .single .post-meta .author{
        font-size:0.9rem;
    }

// Admonition font size decrease
    .single .content .admonition {
        font-size:0.9rem;
    }

// Making hover color working. Note that !important will override variable from global variables. Not recommended but its fine.
    a[href] strong {
        &:hover {
          color: #fe5186 !important; // new hover color
        }
}

// decrease code font size
    code {
        font-size: 0.8rem !important;
    }

.underline { text-decoration: underline; }

// Code showing bold :(
.single .content code {
        font-family: monospace, monospace;
    }

// Finally fixed the reading progress
    #reading-progress {
        position: fixed;
        width: 100%;
        height: 5px;
        z-index: 9999;
        top: 0px; // 55px
        left: 0
    }
    
    #reading-progress-fill {
        height: 5px;
        width: 0
    }
    
    #reading-progress-fill {
        -webkit-transition: width 100ms ease;
        -o-transition: width 100ms ease;
        transition: width 100ms ease;
        background-color: rgba(254, 81, 134,1)
    }

// summary tags

    summary:hover {
        background: #403340;
    }

    summary:active {
        background: #171217;
    }
